.BacktoTop {
    position: relative;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gotodown {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backtodowntext {
    width: 80px;
}

.btndown {
    margin-top: -60px;
    width: 200px;
}

.backtotop {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 120;
    background-color: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
    cursor: pointer;
}

.backtotop>img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:430px) {
    

    .BacktoTop {
        display: none;
    }
}