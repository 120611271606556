.loginRegister-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    background-color: #03a9f4;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s ease;
}
.logo-lr{
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 999;
    display: flex;
}
.logo-lr>a>img{
    width: 160px;
    cursor: pointer;
}
.logo-lr>div{
    width: 30px;
    height: 30px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 19% 81% 71% 29% / 37% 50% 50% 63% ;
    outline: none;
    margin-left: 10px;
    animation: aminated linear infinite;
    animation-duration: 12s;

}
@keyframes aminated {
    0% {
        border-radius: 72% 28% 52% 48% / 58% 78% 22% 42%;
    }

    25% {
        border-radius: 87% 13% 46% 54% / 56% 34% 66% 44%;
    }

    50% {
        border-radius: 15% 85% 6% 94% / 78% 34% 66% 22%;
    }

    75% {
        border-radius: 32% 68% 59% 41% / 66% 34% 66% 34%;
    }

    100% {
        border-radius: 72% 28% 52% 48% / 58% 78% 22% 42%;
    }

}
.square {
    position: absolute;
    width: 100px;
    height: 100px;
    backdrop-filter: blur(5px);
    border-right: 1px solid rgba(255, 255, 225, 0.2);
    border-bottom: 1px solid rgba(255, 255, 225, 0.2);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    border-radius: 10px;
    animation: animated 10s linear infinite;
  }
  
  .square1 {
    left: 100px;
  }
  
  .square2 {
    right: 100px;
    top: 10%;
    width: 60px;
    height: 60px;
  }
  
  .square3 {
    bottom: 10%;
    left: 500px;
    width: 50px;
    height: 50px;
  }
  
  .square4 {
    bottom: 20%;
    right: 200px;
  
  }
  
  .square5 {
    top: 50px;
    left: 600px;
    width: 30px;
    height: 30px;
  }
  
  @keyframes animated {
  
    0%,
    100% {
      transform: translateY(-20px);
    }
  
    50% {
      transform: translateY(20px);
    }
  
  }
  


.loginRegister-wrapperRed {
    background-color: #f43648;
}

.loginRegister-container {
    position: relative;
    width: 800px;
    height: 500px;
    margin: 20px;
    z-index: 500;
}

.loginRegister-Box {
    position: absolute;
    width: 100%;
    height: 420px;
    top: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box>h2 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.box>button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.fromBx {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.25);
    transition: 0.5s ease;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}


.fromActive {
    left: 50%;
}

.fromBx .form {
    position: absolute;
    inset: 2;
    background-color: white;
    width: 100%;
    padding: 0px 50px;
    left: 0;
    transition: 0.5s;
}

.fromBx .signinFrom {
    transition-delay: 0.25s;
    opacity: 1;
}

.fromActive .signinFrom {
    transition-delay: 0s;
    left: -100%;
    opacity: 0;
}

.fromBx .signUpFrom {
    left: 100%;
    transition-delay: 0s;
    opacity: 0;

}

.fromActive .signUpFrom {
    transition-delay: 0.25s;
    left: 0;
    opacity: 1;
}

.fromBx .form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fromBx .form >h3 {
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
    margin-bottom: 35px;
    position: relative;
}

.fromBx .form >h3::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 40px;
    height: 4px;
    background-color: #333;
    border-radius: 20px;
}
.fromBx .form form .inputbox {
    position: relative;
    width: 300px;
    margin-bottom: 30px;
}

.fromBx .form form .inputbox>input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    letter-spacing: 1px;
    z-index: 10;
}

.fromBx .form form .inputbox>span {
    position: absolute;
    left: 0;
    padding: 20px 5px 10px;
    font-size: 1em;
    color: #333;
    pointer-events: none;
    transition: 0.3s;
}

.fromBx .form form .inputbox>input:valid~span,
.fromBx .form form .inputbox>input:focus~span {
    color: #03a9f4;
    transform: translateY(-34px);
    font-size: 0.75em;
}
.fromActive .form form .inputbox>input:valid~span,
.fromActive .form form .inputbox>input:focus~span {
    color: #f43648;
    
}
.fromBx .form form .inputbox>i {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #03a9f4;
    height: 2px;
    border-radius: 4px;
    pointer-events: none;
    transition: 0.3s;
    z-index: 5;
}
.fromActive .form form .inputbox>i {
   background-color: #f43648;
}

.fromBx .form form .inputbox>input:valid~i,
.fromBx .form form .inputbox>input:focus~i {
    height: 44px;

}

.loginbtn{
    border: 2px solid #03a9f4;
    margin-bottom: 20px;
}
.loginbtn::before {
    background-color: #03a9f4;
    box-shadow: 5px -8px 0px #03a9f4, 5px 8px 0px #03a9f4;
}
.loginbtn:hover::before {
    box-shadow: 5px 0px 0px #03a9f4, 5px 0px 0px #03a9f4;
}
.loginbtn::after {
    background-color: #03a9f4;
    box-shadow: -5px -8px 0px #03a9f4, -5px 8px 0px #03a9f4;
}
.loginbtn:hover::after {
    box-shadow: -5px 0px 0px #03a9f4, -5px 0px 0px #03a9f4;
}
.Registerbtn{
    border: 2px solid #f43648;
}
.Registerbtn::before {
    background-color: #f43648;
    box-shadow: 5px -8px 0px #f43648, 5px 8px 0px #f43648;
}
.Registerbtn:hover::before {
    box-shadow: 5px 0px 0px #f43648, 5px 0px 0px #f43648;
}
.Registerbtn::after {
    background-color: #f43648;
    box-shadow: -5px -8px 0px #f43648, -5px 8px 0px #f43648;
}
.Registerbtn:hover::after {
    box-shadow: -5px 0px 0px #f43648, -5px 0px 0px #f43648;
}
.fromBx .form form>a {
    color: #333;
    cursor: pointer;
    font-size: 16px;
}

@media screen and (max-width: 991px) {
    .loginRegister-container {
        max-width: 400px;
        height: 650px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loginRegister-Box {
        top: 0;
        height: 100%;
    }

    .fromBx {
        width: 100%;
        height: 500px;
        top: 0;
        box-shadow: none;
    }

    .loginRegister-Box .box {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 150px;
    }

    .signIn {
        top: 0;
    }

    .fromActive {
        left: 0;
        top: 150px;
    }
}

@media screen and (max-width:650px) {  
    .square3,
    .square5 {
      display: none;
  
    }
  }
  