.navbar-wrapper {
    width: 100%;
}

.navbar-top {
    background-color: #F2F5FA;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
}

.navbar-top>p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #414A4D;
    margin-right: 20px;
}

.navbar-bottom-wrapper {
    width: 100%;
    background-color: white;
    box-shadow: 0 1px 20px 0 rgb(0 0 0 / 14%);
    z-index: 1;
    position: relative;
    background: #fff;
    transition: 0.5s;
    position: relative;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    transition: 0.5s;
    z-index: 99;
}

.navbar-bottom {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.navbar-logo {
    width: 20%;
    text-transform: uppercase;
    padding: 0px 20px;
}

.navbar-logo>img {
    width: 100%;
    height: 100%;
}

.navbar-menu {
    width: 80%;
    display: flex;
    justify-content: right;
}

.navbar-menu>ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
}

.navbar-menu>ul>li,
.navbar-menu>ul>a>li {
    position: relative;
    padding: 0 12px;
    color: #595959;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease;
}

.navbar-menu>ul>a>li::after {
    content: '';
    position: absolute;
    width: 90%;
    height: 3px;
    border-radius: 10px;
    background-color: #3586ff;
    left: 6px;
    bottom: -21px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
}


.active>li::before {
    content: '';
    position: absolute;
    width: 90%;
    height: 3px;
    background-color: #3586ff;
    border-radius: 10px;
    left: 6px;
    bottom: -21px;
    transform: scaleX(1);
    transform-origin: left;
    transition: 0.5s ease-in-out;
}

.navbar-menu>ul>a>li:hover {
    color: #3586ff;

}

.navbar-menu>ul>a>li:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.bar {
    width: 30px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 7px auto;
    display: block;
    transition: 0.5s ease-out;
    transition-delay: 0.4s;
    border-radius: 5px;
}

.toggles .bar:nth-child(2) {
    opacity: 0;
}

.toggles .bar:nth-child(1) {
    transform: translateY(10px) rotate(490deg);
}

.toggles .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-490deg);
}

.hanburgurmenu {
    width: 30px;
    margin: 0px 5px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: none;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #414A4D;
    margin: 3px auto;
    border-radius: 10px;
    transition: 0.3s ease;
    

}

.menuActive .line {
    transition: 0.3s ease;
    
}

.menuActive .line:nth-child(1) {
    transform: translateY(8px) rotate(40deg);
}

.menuActive .line:nth-child(2) {
    scale: 0;
    opacity: 0;
}

.menuActive .line:nth-child(3) {
    transform: translateY(-8px) rotate(-40deg);
}

@media screen and (max-width:960px) {
    .navbar-top>p {
        font-size: 12px;
    }

    .navbar-top .btn {
        font-size: 12px;

    }
}

@media screen and (max-width:900px) {
    .navbar-top>p {
        font-size: 10px;
    }

    .navbar-top .btn {
        font-size: 10px;
    }

    .navbar-menu>ul {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: white;
        flex-direction: column;
        top: -300px;
        transition:top 0.3s ease;
        z-index: -100;
    }
    .navbar-menuActive>ul{
        top: 52px;
    }

    .navbar-menu>ul>a>li {
        margin: 10px 0px;
    }

    .navbar-menu>ul>a>li::after,
    .active>li::before {
        bottom: 0;
    }

    .navbar-logo {
        width: 25%;
    }

    .hanburgurmenu {
        display: flex;

    }
}
@media screen and (max-width:712px) {
.navbar-logo{
    width: 230px;
}
     .navbar-top>p {
        font-size: 7px;
    }

    .navbar-top .btn {
        font-size: 7px;
        padding: 6px;
    }
}
@media screen and (max-width:530px) {
  
    .navbar-top>p {
       font-size: 5px;
       line-height:1.2;
   }

   .navbar-top .btn {
       font-size: 5px;

   }
}