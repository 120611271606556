.footer {
    position: relative;
    width: 100%;
    background-color: #3586ff;
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    background: url(../../Assets/wave.png);
    width: 100%;
    height: 100px;
    background-size: 1000px 100px;
    overflow: hidden;

}

.wave#wave1 {
    z-index: 99;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}

.wave#wave2 {
    z-index: 98;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}

.wave#wave3 {
    z-index: 100;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}

.wave#wave4 {
    z-index: 98;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-position-x: 0px;
    }
}

@keyframes animateWave_02 {
    0% {
        background-position-x: 0px;
    }

    100% {
        background-position-x: 1000px;
    }
}

.footer-wrapper {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;

}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.footer-logo>span {
    margin: 0px 12px;
    opacity: 0.9;
    cursor: pointer;
    transition: 0.3s ease;
}
.footer-logo>span:hover{
    transform: translateY(-10px);
}
.footer-item>ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.footer-item>ul>a>li {
    position: relative;
    color: white;
    font-size: 1em;
    cursor: pointer;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.9;
    margin: 0px 10px;
}

.footer-item>ul>a>li::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: #fff;
    left: 0;
    bottom: -5px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
}

.footer-item>ul>a>li:hover::before {
    transform: scaleX(1);
    transform-origin: left;

}

.footer-copy {
    position: relative;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #fff;
    display: flex;
    font-size: 0.9em;
    padding: 5px;
}

.footer-copy>p {
    display: flex;
    align-items: flex-end;
}

.footer-copy>img {
    width: 40px;
}
@media screen and (max-width:620px) {
    .footer-item>ul>a>li{
        font-size: 13px;
    }
    .footer-logo>span>svg{
        width: 26px;
        height: 26px;
    }
    .footer-copy>p {
        font-size: 12px;
    }
    .footer-copy>img{
        width: 32px;
    }
}