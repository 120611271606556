.main-wrapper {
    position: relative;
    width: 100%;
    height: 700px;
    background: linear-gradient(rgba(31, 119, 219, 0.1), rgba(31, 119, 219, 0.1)), url(../../Assets/banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
}

.skewOne {
    position: absolute;
    bottom: -100%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: skewY(-15deg);
    transition: 0.5s linear;
    transform-origin: top left;
}

.skewtwo {
    position: absolute;
    bottom: -100%;
    right: 50%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: skewY(15deg);
    transition: 0.5s linear;
    transform-origin: top right;
}

.skewOneNew {
    transform: skewY(0deg);
}

.main {
    position: relative;
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.main-l {
    margin-top: 120px;
    padding: 5px;
    width: 60%;
    height: 300px;
}

.main-l>h4 {
    font-size: 18px;
    margin-bottom: 5px;
    display: block;
    font-weight: 400;
    color: #ffffff;
}

.main-l>h1 {
    font-size: 54px;
    margin-bottom: 25px;
    line-height: 1.3;
    color: #fff;
}

.main-l>h1>span {
    color: #1e88e5;
}

.main-l>p {
    font-size: 16px;
    margin-bottom: 40px;
    color: #ddd;
    line-height: 1.7
}

.mainbtn {
    border: 2px solid #1e88e5;
    background-color: #1e88e5;
    color: #fff;
    padding: 12px;
    max-width: 150px;
}

.mainbtn:hover,
.mainbtn>span:hover {
    color: #111;
}

.mainbtn::before {
    background-color: #fff;
    box-shadow: 5px -8px 0px #fff, 5px 8px 0px #fff;
}

.mainbtn:hover::before {
    box-shadow: 5px 0px 0px #fff, 5px 0px 0px #fff;
}

.mainbtn::after {
    background-color: #fff;
    box-shadow: -5px -8px 0px #fff, -5px 8px 0px #fff;
}

.mainbtn:hover::after {
    box-shadow: -5px 0px 0px #fff, -5px 0px 0px #fff;
}

.main-r {
    position: relative;
    width: 40%;
    height: 300px;
}

.main-r>img {
    position: absolute;
    top: 65px;
    width: 600px;
}

@media screen and (max-width:1100px) {
    .main-r>img {
        right: -100px;
        width: 500px;
        top: 155px;
    }
}
@media screen and (max-width:750px) {
    .main-l{
        margin-top: 100px;
    }
    .main-l>h4 {
        font-size: 15px;
    }
    .main-l>h1 {
        font-size: 48px;
    }
    .main-l>p {
        font-size: 12px;
        width: 90%;
    }
    .mainbtn{
        padding: 10px;
    }
    .mainbtn>span{
        font-size: 13px;
    }
}
@media screen and (max-width:600px){
    .main-r {
        display: none;
    }
    .main-l{
        width: 100%;
    }
    .main-l>p {
        width: 100%;
    }
}
@media screen and (max-width:400px) {

    .main-l>h1 {
        font-size: 42px;
    }
}